<template>
<div>
<div v-if="RenamePlaylistDialogVisible">
    <RenamePlaylistModal :RenamePlaylistDialogVisible="RenamePlaylistDialogVisible" :closeRenamePlaylistModal="closeRenamePlaylistModal" :selectedPlaylist="selectedPlaylist" @closeRenameModalWihRename="closeRenamePlaylistModalWithrename" />
</div>

<div v-if="deletePlaylistDialogVisible">
    <DeletePlaylistModal :deletePlaylistDialogVisible="deletePlaylistDialogVisible" :closeDeletePlaylistModal="closeDeletePlaylistModal" :selectedPlaylist="selectedPlaylist" :closeDeletePlaylistModalWithdelete="closeDeletePlaylistModalWithdelete" />
</div>



<div class="px-4 sm:px-6 lg:px-4 xl:px-6 pt-4 pb-4 sm:pb-6 lg:pb-4 xl:pb-6  sm:pt-6 lg:pt-4 xl:pt-6 space-y-4 h-full">

    <div class="flex h-full w-full border rounded-lg text-center  justify-center items-center" :class="isLoading ? 'hidden' : (dark ? 'border-gray-700 bg-gray-700' : '')">
        <div v-if="playlists.length == 0" >
          <el-result
            icon="info"
            title="Aucune playlist"
            subTitle="Il y a aucune ancienne playlist veuillez créer une nouvelle "
          >
          </el-result>
        </div>

        <div v-else class="h-full w-full p-4">

          <div  class="inline-flex w-full justify-between items-center mb-5">
            <div class="relative w-1/2">
              <el-input v-model="searchPlaylist" placeholder="Recherche par titre" />
              <button  class="absolute right-0 top-0 my-3 mr-3">
                <svg class="h-4 w-4 fill-current text-gray-700" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" style="enable-background:new 0 0 56.966 56.966;" xml:space="preserve" width="512px" height="512px">
                  <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z"/>
                </svg>
              </button>


            </div>
            <div></div>
          </div>


          <div class="mx-4 mb-3">
            <!-- <el-scrollbar height="450px" class="w-full rounded-lg bg-white"> -->
              <div :class="dark ? 'text-white bg-gray-600 border-gray-600 hover:text-gray-700 hover:bg-white hover:border-gray-600' : 'hover:bg-red-50 hover:border-red-400'" v-for="playlist in filterPlaylists" :key="playlist.id" class="flex sm:flex-row flex-col justify-between items-center border rounded-lg px-6 py-2 my-2 sm:mx-6 transition duration-500 transform-gpu hover:scale-105">
                  <div class="sm:w-48">
                      <p>{{ playlist.name }}</p>
                  </div>

                  <div class="sm:w-48 w-full my-1">
                    <div class="flex flex-col justify-center">
                      <p class="text-sm">{{playlist.answered}}/{{playlist.total}}</p>

                      <el-popover
                        placement="top-start"
                        :width="200"
                        trigger="hover"
                      >
                        <template #reference>
                          <el-progress  :percentage="playlist.total != 0 ?  parseInt((playlist.answered/playlist.total)*100) : 0"  :text-inside="true" :stroke-width="20" />
                        </template>
                        <div>
                          <Chart type="doughnut" :data="playlist.chartData" />
                        </div>
                      </el-popover> 

                    </div>
                  </div>

                  <div class="sm:w-24 w-full sm:mt-0 mt-3">
                    <div class="flex  justify-between item-center ">
                        <div :class="(Subscribed)? 'block' : 'opacity-0 pointer-events-none'" @click="goSession(playlist.id)" class="w-4 mr-2 transform hover:text-red-400 hover:scale-110">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" width="20" height="20" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" ><polygon points="5 3 19 12 5 21 5 3"></polygon></svg>
                        </div>
                        <div @click="renameFunction(playlist.id,playlist.name)"  class="w-4 mr-2 transform hover:text-red-400 hover:scale-110">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="20" height="20" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                            </svg>
                        </div>
                        <div @click="deleteFunction(playlist.id)" class="w-4 mr-2 transform hover:text-red-400 hover:scale-110">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="20" height="20" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </div>
                    </div>
                  </div>
              </div>
            <!-- </el-scrollbar> -->
          </div>

            



        </div>
    </div>

    <div
      :class="isLoading ? 'block' : 'hidden'" class="flex items-center justify-center h-full w-full">
      <!--<div class="fulfilling-square-spinner">
        <div class="spinner-inner"></div>
      </div>-->
      <div class="loading">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>

</div>
</div>
</template>

<script>
import { reactive, toRefs  } from 'vue'
import {Plus} from '@element-plus/icons';
import RenamePlaylistModal from './PlaylistModals/RenamePlaylistModal.vue'
import DeletePlaylistModal from './PlaylistModals/DeletePlaylistModal.vue'
import { ElMessage } from 'element-plus';
import Chart from 'primevue/chart';
import axiosIns from '../../plugins/axios';


export default {
  components: {
    Plus,
    RenamePlaylistModal,
    DeletePlaylistModal,
    Chart
  },
  
  setup() {
  const state = reactive({
    percentage: 60,
  })
  const errorm = () => {
    ElMessage.error('Une erreur est survenue !')
  }

  return {
    ...toRefs(state),
    errorm
  }
},
    data () {
        return {
            
            RenamePlaylistDialogVisible:false,
            deletePlaylistDialogVisible:false,
            isLoading : false,
            searchPlaylist: null,
            playlists : [],
            currentRoute: window.location.pathname,
            selectedPlaylist: null,
        }
    },
    props:{
        linkPath:Function,
        dark: Boolean
    },

    methods: {
 
      closeRenamePlaylistModal(){
        this.RenamePlaylistDialogVisible = false;
        this.selectedPlaylist = null;
      },

      closeRenamePlaylistModalWithrename(name){
        
        this.playlists.forEach(element => {
           if(element.id === this.selectedPlaylist.id){
             element.name = name;
           }
        });
        this.RenamePlaylistDialogVisible = false;
        this.selectedPlaylist = null;
      },


      closeDeletePlaylistModal(){
        this.deletePlaylistDialogVisible = false;
        this.selectedPlaylist = null;
      },
       closeDeletePlaylistModalWithdelete(){
        this.playlists = this.playlists.filter((tab) => tab.id !== this.selectedPlaylist);
        this.deletePlaylistDialogVisible = false;
        this.selectedPlaylist = null;
      },


      goSession(id){
        this.$router.push("/dashboard/currentSession/?id="+id);
      },

      deleteFunction(id){
        this.deletePlaylistDialogVisible = true;
        this.selectedPlaylist = id;
      },

      renameFunction(id,name){
        this.RenamePlaylistDialogVisible = true;
        this.selectedPlaylist = {id: id,name: name};
      }

    },

    mounted () {

        this.linkPath(this.currentRoute);

        this.isLoading = true
        axiosIns.get("/sessionsplaylistsofuser/"+this.User.id+"/"+this.SubscribeNow)
          .then(({ data }) => {
            this.isLoading = false;
            
            let temps = []
            data.message.forEach(element => {

              const item = {
                ...element,
                chartData: {
                  labels: ['Correctes','Fausses','Non répondues'],
                  datasets: [
                    {
                      data: [element.noteData.true, element.noteData.false, element.noteData.unanswered],
                      backgroundColor: [
                          "#34D399",
                          "#F87171",
                          "#D1D5DB"
                      ],
                      hoverBackgroundColor: [
                          "#34D399",
                          "#F87171",
                          "#D1D5DB"
                      ]
                    }
                  ]
                }
              }
              temps.push(item);
            });

            this.playlists = temps;
          })
          .catch(() => {
            this.errorm();
            this.isLoading = false;
          });
    },

    computed: {
        isToken() {
            return this.$store.getters.get_token;
        },
        User() {
          return this.$store.getters.get_user;
        },
        filterPlaylists(){
            if(this.searchPlaylist){
                return this.playlists.filter((item)=>{
                    return item.name.toLowerCase().includes(this.searchPlaylist.toLowerCase())
                })
            }else{
                return this.playlists
            }
        },
        SubscribeNow(){
          return this.$store.getters.get_subscribeNow
        },
        Subscribed(){
          return this.$store.getters.get_subscriptions.includes(String(this.$store.getters.get_subscribeNow))
        },
    },

}
</script>


<style>
.fulfilling-square-spinner,
.fulfilling-square-spinner * {
  box-sizing: border-box;
}

.fulfilling-square-spinner {
  height: 100px;
  width: 100px;
  position: relative;
  border: 4px solid #f87171;
  animation: fulfilling-square-spinner-animation 4s infinite ease;
}

.fulfilling-square-spinner .spinner-inner {
  vertical-align: top;
  display: inline-block;
  background-color: #f87171;
  width: 100%;
  opacity: 1;
  animation: fulfilling-square-spinner-inner-animation 4s infinite ease-in;
}

@keyframes fulfilling-square-spinner-animation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fulfilling-square-spinner-inner-animation {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}
</style>